<template>
  <div class="single-article-page">
    <loader full :visible="isLoading" v-if="isLoading" />
    <div class="not-found" v-else-if="notFound">
      <b-row>
        <b-col cols="12" md="5" data-aos="fade-up">
          <h2 class="mb-4">{{ $t('404_error') }}</h2>
          <p class="mb-4 not-found-desc">
            {{ $t('404_desc') }}
          </p>
          <router-link
            class="button button--default button--block"
            :to="'/' + $i18n.locale"
          >
            {{ $t('return_to_main') }}
          </router-link>
        </b-col>
      </b-row>
    </div>
    <article class="single-article" v-else>
      <div class="single-article__details">
        <router-link
          :to="`/${$i18n.locale}/category/${singleNews.category.slug}`"
          class="single-article__category"
        >
          {{ singleNews.category[`name_${$i18n.locale}`] }}
        </router-link>
        <div class="single-article__detail time">
          {{
            $date(singleNews.publish_at || singleNews.published_at).format(
              'HH:mm'
            )
          }}
        </div>
        <div class="single-article__detail date">
          {{
            $date(singleNews.publish_at || singleNews.published_at).format(
              'DD.MM.YYYY'
            )
          }}
        </div>
        <div class="single-article__detail views">
          <eye-icon class="icon" size="1.3x" />
          <span>{{ singleNews.views }}</span>
        </div>
      </div>
      <h2 class="single-article__title">
        {{ singleNews[`title_${$i18n.locale}`] }}
      </h2>
      <div class="single-article__content">
        <div class="image-wrapper">
          <img
            :src="baseUrl + singleNews.thumbnail.url"
            :alt="singleNews.thumbnail.alternativeText"
          />
          <div class="image-wrapper__hint">
            Фото: {{ singleNews.thumbnail.alternativeText }}
          </div>
        </div>
        <div v-html="singleNews[`content_${$i18n.locale}`]"></div>
      </div>
      <!-- <div class="single-article__hashtags">
        <a href="#" class="single-article__hashtag">#Урганч</a>
        <a href="#" class="single-article__hashtag">#зиёратгоҳ</a>
        <a href="#" class="single-article__hashtag">#тарихий обида</a>
        <a href="#" class="single-article__hashtag">#Дошқинжон бобо</a>
      </div> -->
    </article>

    <div class="recommendation mt-5">
      <h3 class="mb-3">{{ $t('recommend') }}</h3>
      <b-row>
        <b-col
          v-for="news in recommendedNews"
          :key="news.id"
          cols="12"
          md="6"
          class="my-3"
        >
          <card-item :item="news" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { EyeIcon } from 'vue-feather-icons'
import CardItem from '@/components/cards/CardItem.vue'
import Loader from '@/components/Loader'
import marked from 'marked'

export default {
  components: {
    EyeIcon,
    CardItem,
    Loader
  },
  data: () => ({
    isLoading: false,
    notFound: false,
    recommendedNews: []
  }),
  computed: {
    singleNews() {
      return this.$store.state.newsModule.singleNews
    },
    compiltedMarkdown() {
      return marked(this.singleNews?.content_uz)
    }
  },
  created() {
    this.$store
      .dispatch('fetchNews', { _limit: 6 })
      .then(news => (this.recommendedNews = news))
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.isLoading = true
        this.$store
          .dispatch('fetchSingleNews', this.$route.params.id)
          .then(() => {
            this.notFound = false
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.notFound = true
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>
